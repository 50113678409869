import React, { useState } from 'react'
import { Game } from './Game'
import { getRandomWord } from '../../data/words/words'
import { track } from '@vercel/analytics'

export const GameWrapper = () => {
    const [answer, setAnswer] = useState<string>(getRandomWord())

    return (
        <Game
            answer={answer}
            resetAnswer={() => {
                setAnswer(getRandomWord())
                track('PlayAgain')
            }}
        />
    )
}
