export const Ch_WORDS = [
    'ČUVAR',
    'ČUČANj',
    'ČEKIĆ',
    'ČISTO',
    'ČESMA',
    'ČELIK',
    'ČORBA',
    'ČUDAN',
    'ČIZMA',
    'ČITAV',
    'ČUDAK',
    'ČOBAN',
    'ČIODA',
    'ČITAČ',
    'ČESMA',
    'ČAURA',
    'ČAVAO',
    'ČUDNO',
    'ČIPKA',
    'ČIGRA',
    'ČOVEK',
    'ČETKA',
    'ČAMAC',
    'ČEŠALj',
    'ČOPOR',
    'ČVRST',
    'ČUDNO',
]
