export const U_WORDS = [
    'UZDAH',
    'UVALA',
    'ULICA',
    'UPALA',
    'UKRAS',
    'UDICA',
    'UKRAS',
    'USPON',
    'UNIJA',
    'ULOGA',
    'UŽINA',
    'USPEH',
    'USTAV',
    'USLOV',
]
