import React, { FunctionComponent } from 'react'
import wordmark from '../../assets/wordmark.png'

export interface HeaderProps {}

export const Header: FunctionComponent<HeaderProps> = () => {
    return (
        <div
            style={{
                display: 'flex',
                marginTop: '1em',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <img src={wordmark} style={{ width: '50%' }} alt="wordmark.png" title="Wordmark of game" />
        </div>
    )
}
