import React, { FC } from 'react'
import { range } from '../../../service/utils'
import { NUM_OF_GUESSES_ALLOWED } from '../../../data/constants'
import { Guess } from './Guess'

interface GamePanelProps {
    answer: string
    guessList: string[][]
    activeGuess: string[]
    activeGuessIndex: number
}

const GamePanel: FC<GamePanelProps> = ({ guessList, activeGuess, activeGuessIndex, answer }) => {
    return (
        <div style={{ height: '100%' }}>
            <div>
                {range(NUM_OF_GUESSES_ALLOWED).map((index) => {
                    const guessValue = index === activeGuessIndex ? activeGuess : guessList[index] ? guessList[index] : []
                    return <Guess key={index} answer={answer} guess={guessValue} isActive={index === activeGuessIndex} />
                })}
            </div>
        </div>
    )
}

export default GamePanel
