import { NUM_OF_GUESSES_ALLOWED } from '../../data/constants'

export const getGuessResult = (guess: string[], answer: string): any[] => {
    if (!guess) return []

    const guessArray = guess
    const answerArray = getAnswerArray(answer)
    const solvedGuessIndexes: number[] = []
    const solvedAnswerIndexes: number[] = []

    const result: any[] = []

    guessArray.forEach((letter, index) => {
        if (guessArray[index] === answerArray[index]) {
            result[index] = { letter: guessArray[index], status: 'correct' }
            solvedGuessIndexes.push(index)
            solvedAnswerIndexes.push(index)
        }
    })

    guessArray.forEach((letter, index) => {
        if (!solvedGuessIndexes.includes(index)) {
            const misplacedIndex = answerArray.findIndex(
                (char, answerIndex) => char === guessArray[index] && !solvedAnswerIndexes.includes(answerIndex),
            )
            if (misplacedIndex >= 0 && !solvedAnswerIndexes.includes(misplacedIndex)) {
                result[index] = { letter: guessArray[index], status: 'misplaced' }
                solvedGuessIndexes.push(index)
                solvedAnswerIndexes.push(misplacedIndex)
            } else {
                result[index] = { letter: guessArray[index], status: 'incorrect' }
            }
        }
    })

    return result
}

export const getUpdatedStatusMap = (statusMap: Map<string, string>, guess: string[], answer: string): Map<string, string> => {
    const results = getGuessResult(guess, answer)
    const priority = ['correct', 'misplaced', 'incorrect', 'inactive']
    results.forEach((result) => {
        if (statusMap.has(result.letter)) {
            const oldStatus = statusMap.get(result.letter) || 'inactive'
            if (priority.indexOf(result.status) < priority.indexOf(oldStatus)) {
                statusMap.set(result.letter, result.status)
            }
        }
    })
    return new Map(statusMap)
}

export const getGameStatus = (guessList: string[][], answer: string): 'won' | 'lost' | 'active' => {
    if (guessList.length === 0) return 'active'
    if (guessList[guessList.length - 1].join('') === answer) return 'won'
    if (guessList.length === NUM_OF_GUESSES_ALLOWED) return 'lost'

    return 'active'
}

export const getAnswerArray = (answer: string): string[] => {
    const array = answer.split(/(?:(Nj|Lj|Dž)|(.))/).filter(Boolean)
    return array
}
