export const R_WORDS = [
    'RIZIK',
    'RAZNO',
    'REČNI',
    'ROGAČ',
    'RUČAK',
    'RUKAV',
    'RAČUN',
    'RUČKA',
    'RERNA',
    'RADIO',
    'RADAR',
    'RADNO',
    'REKET',
    'RANAC',
    'RASOL',
    'RAVAN',
    'RAVNO',
    'REGAL',
    'RESOR',
    'REBRO',
    'REJON',
    'RVANjE',
    'RETKO',
    'RIBAR',
    'RIZLA',
    'ROBOT',
    'ROĐAK',
    'RUDAR',
    'RAGBI',
    'RITAM',
]
