import { FC, useEffect } from 'react'
import useSound from 'use-sound'
import lost from '../../../sounds/lost.wav'
import { track } from '@vercel/analytics'

export interface LostBannerProps {
    sound: boolean
    answer: string
}

export const LostBanner: FC<LostBannerProps> = ({ sound, answer }) => {
    const [play] = useSound(lost)

    useEffect(() => {
        track('GameDone', { result: 'loss' })
        if (sound) {
            play()
        }
    }, [sound, play])

    return (
        <p>
            Nažalost, izgubili ste. Tačna reč je: <strong>{answer}</strong>
        </p>
    )
}
