export const B_WORDS = [
    'BORAC',
    'BOGAT',
    'BIVŠA',
    'BRAVA',
    'BRIGA',
    'BAZEN',
    'BASNA',
    'BESAN',
    'BACAČ',
    'BATAK',
    'BAČVA',
    'BAGER',
    'BAGET',
    'BAGRA',
    'BAHAT',
    'BAJAT',
    'BAREL',
    'BAŠTA',
    'BDENjE',
    'BEDEM',
    'BEDAN',
    'BILjNO',
    'BONUS',
    'BITNO',
    'BLAGO',
    'BLATO',
    'BLIZU',
    'BODLjA',
    'BRAĆA',
    'BOŽUR',
    'BRADA',
    'BREME',
    'BREZA',
    'BUBNA',
    'BUKET',
    'BUTAN',
    'BOLjKA',
    'BADEM',
    'BRUKA',
    'BOMBA',
    'BUREK',
    'BURMA',
    'BUNDA',
    'BANKA',
    'BUNAR',
    'BRATI',
    'BORBA',
    'BOGAT',
    'BUKVA',
    'BISER',
    'BOLNO',
    'BAJKA',
    'BAKAR',
    'BALON',
    'BUSEN',
    'BATAK',
    'BERBA',
    'BERZA',
    'BETON',
    'BIBER',
    'BIZON',
    'BAKLjA',
    'BOKAL',
    'BITKA',
    'BILjKA',
    'BRAON',
    'BREND',
    'BUBANj',
    'BODEŽ',
]
