import React, { FunctionComponent, useEffect, useState } from 'react'
import { GUESS_LENGTH, INITIAL_LETTERS_STATUS_MAP } from '../../data/constants'
import GamePanel from './Panel/GamePanel'
import { Keyboard } from './Keyboard/Keyboard'
import { getGameStatus, getUpdatedStatusMap } from '../../service/game/gameService'
import { Banner } from './Banner/Banner'
import { GameHeader } from './Header/GameHeader'
import { RulesModal } from '../Rules/RulesModal'
import { SocialLinksModal } from './SocialLinks/SocialLinksModal'
import { track } from '@vercel/analytics'

export interface GameProps {
    answer: string
    resetAnswer: () => void
}

export const Game: FunctionComponent<GameProps> = ({ answer, resetAnswer }) => {
    const [letterStatusMap, setLetterStatusMap] = useState<Map<string, string>>(new Map(INITIAL_LETTERS_STATUS_MAP))
    const [guessList, setGuessList] = useState<string[][]>([])
    const [guessListIndex, setGuessListIndex] = useState<number>(0)

    const [activeGuess, setActiveGuess] = useState<string[]>([])

    const [openRules, setOpenRules] = useState(false)
    const [openSocial, setOpenSocial] = useState<boolean>(false)

    useEffect(() => {}, [])

    return (
        <div
            style={{
                margin: '2px',
                height: '100%',
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
        >
            <div>
                <h3 style={{ display: 'none' }}>Igra</h3>
                <GameHeader onRulesClick={() => setOpenRules(true)} />
            </div>
            <div
                style={{
                    padding: '16px',
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div>
                        <GamePanel answer={answer} guessList={guessList} activeGuess={activeGuess} activeGuessIndex={guessListIndex} />
                    </div>
                </div>
                <div>
                    <Keyboard
                        statusMap={letterStatusMap}
                        active={getGameStatus(guessList, answer) === 'active'}
                        onKeyClick={(letter) => {
                            if (activeGuess && activeGuess.length === GUESS_LENGTH) return

                            const newActiveGuess = [...activeGuess]
                            newActiveGuess.push(letter)
                            setActiveGuess(newActiveGuess)
                        }}
                        onEnter={() => {
                            if (!activeGuess || activeGuess.length !== GUESS_LENGTH) return

                            setGuessList([...guessList, activeGuess])
                            setGuessListIndex(guessListIndex + 1)
                            setActiveGuess([])
                            const newStatusMap = getUpdatedStatusMap(letterStatusMap, activeGuess, answer)
                            setLetterStatusMap(newStatusMap)
                        }}
                        onDelete={() => {
                            if (activeGuess.length === 0) return

                            const newActiveGuess = [...activeGuess]
                            newActiveGuess.pop()
                            setActiveGuess(newActiveGuess)
                        }}
                    />
                </div>
                {getGameStatus(guessList, answer) !== 'active' && (
                    <Banner
                        answer={answer}
                        isWin={getGameStatus(guessList, answer) === 'won'}
                        sound={localStorage.getItem('sound') === 'true'}
                        onReset={() => {
                            resetAnswer()
                            setGuessList([])
                            setActiveGuess([])
                            setGuessListIndex(0)
                            setLetterStatusMap(new Map(INITIAL_LETTERS_STATUS_MAP))
                        }}
                        onSocialButtonClick={() => setOpenSocial(true)}
                    />
                )}
            </div>
            {openRules && <RulesModal onClose={() => setOpenRules(false)} />}
            {openSocial && (
                <SocialLinksModal
                    onClose={() => {
                        setOpenSocial(false)
                    }}
                />
            )}{' '}
        </div>
    )
}
