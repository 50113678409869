export const L_WORDS = [
    'LABUD',
    'LAGAN',
    'LAKAT',
    'LEDEN',
    'LEPAK',
    'LIMAR',
    'LOGOR',
    'LOKAL',
    'LOVAC',
    'LUKAV',
    'LASTA',
    'LOŽAČ',
    'LOKVA',
    'LEKAR',
    'LOSOS',
    'LIGNjA',
    'LONAC',
    'LIMUN',
    'LOPTA',
    'LAMPA',
    'LUKAV',
    'LARVA',
    'LIDER',
    'LISAC',
    'LOVOR',
]
