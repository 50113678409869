import React, { FC } from 'react'
import { IoMdClose } from 'react-icons/io'
import { RulesContent } from './RulesContent'

export interface RulesModalProps {
    onClose: () => void
}

export const RulesModal: FC<RulesModalProps> = ({ onClose }) => {
    return (
        <div
            style={{
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
            }}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    padding: '1em',
                    borderRadius: '8px',
                    marginLeft: '0.5em',
                    marginRight: '0.5em',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '16px' }}>
                    <h2>Kako igrati?</h2>
                    <button
                        onClick={onClose}
                        style={{
                            top: '10px',
                            right: '10px',
                            background: 'none',
                            border: 'none',
                            fontSize: '1.5em',
                            cursor: 'pointer',
                        }}
                    >
                        <IoMdClose />
                    </button>
                </div>

                <div style={{ padding: '16px', fontSize: '1.1em' }}>
                    <RulesContent />
                </div>
            </div>
        </div>
    )
}
