export const G_WORDS = [
    'GAJBA',
    'GAZDA',
    'GALEB',
    'GADNO',
    'GOLUB',
    'GRANA',
    'GRADjA',
    'GOVOR',
    'GOTOV',
    'GRUBO',
    'GLINA',
    'GOSTI',
    'GLAVA',
    'GOZBA',
    'GULAŠ',
    'GLAVA',
    'GUSKA',
    'GRUPA',
    'GORKO',
    'GAJBA',
    'GRAĐA',
    'GAREŽ',
    'GALOP',
    'GARDA',
    'GAUDA',
    'GRIPA',
    'GESTA',
    'GLUMA',
    'GOŠĆA',
    'GRAJA',
    'GRANjE',
    'GRMLjE',
    'GROZD',
    'GRUDI',
    'GUSAR',
    'GUŽVA',
]
