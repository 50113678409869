import React, { FC } from 'react'
import { KeyboardKey } from './KeyboardKey'
import { EnterKey } from './EnterKey'
import { DeleteKey } from './DeleteKey'

export interface KeyboardLineProps {
    letters: Map<string, string>
    lineType: 'upper' | 'middle' | 'lower'
    active: boolean
    onKeyClick: (letter: string) => void
    onEnter: () => void
    onDelete: () => void
}

export const KeyboardLine: FC<KeyboardLineProps> = ({ letters, lineType, active, onKeyClick, onEnter, onDelete }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '33%',
                width: '100%',
            }}
        >
            {Array.from(letters).map((letter) => {
                return <KeyboardKey key={letter[0]} letter={letter[0]} active={active} status={letter[1]} onClick={onKeyClick} />
            })}
            {lineType === 'upper' && <DeleteKey active={active} onClick={onDelete} />}
            {lineType === 'lower' && <EnterKey active={active} onClick={onEnter} />}
        </div>
    )
}
