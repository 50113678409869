import React, { FC, useState } from 'react'
import { LostBanner } from './LostBanner'
import { WinBanner } from './WinBanner'
import { BannerButton } from './BannerButton'
import { track } from '@vercel/analytics'
import { shareOnMobile } from 'react-mobile-share'
import { Link } from 'react-router-dom'
import { SocialLinksModal } from '../SocialLinks/SocialLinksModal'

export interface BannerProps {
    answer: string
    isWin: boolean
    sound: boolean
    onReset: () => void
    onSocialButtonClick: () => void
}

export const Banner: FC<BannerProps> = ({ answer, isWin, sound, onReset, onSocialButtonClick }) => {
    const shareMessage = {
        text: 'Pozdrav, ja igram Pogađalicu. Pridruži mi se!',
        url: 'https://pogadjalica.com/igra',
        title: 'Pogađalica',
    }
    const canShare = navigator.canShare && navigator.canShare(shareMessage)

    return (
        <div
            style={{
                position: 'fixed',
                left: '0',
                right: '0',
                bottom: '0',
                width: '100%',
                maxWidth: '500px',
                margin: '0 auto',
                padding: '32px',
                textAlign: 'center',
                animation: 'slideUp 750ms cubic-bezier(0, 0.72, 0.24, 1.02)',
                borderRadius: '12px 12px 0px 0px',
                willChange: 'transform',
                backgroundColor: isWin ? 'var(--color-green)' : 'var(--color-red)',
                color: 'white',
            }}
        >
            {isWin ? <WinBanner sound={sound} answer={answer} /> : <LostBanner sound={sound} answer={answer} />}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    gap: '0.5em',
                    marginTop: '1.5em',
                    marginBottom: '0.5em',
                }}
            >
                <BannerButton onClick={onReset} text={'Igraj ponovo'} size={'l'} />
                {canShare && (
                    <BannerButton
                        onClick={() => {
                            shareOnMobile(shareMessage)
                            track('GameShare')
                        }}
                        text={'Pozovi prijatelje'}
                    />
                )}
                <BannerButton
                    text={'Zapratite nas'}
                    onClick={() => {
                        onSocialButtonClick()
                        track('SocialLinksModalShown')
                    }}
                />
                <Link to={'/ocena'}>
                    <BannerButton
                        onClick={() => {
                            track('OpenGameReview')
                        }}
                        text={'Ostavi recenziju'}
                    />
                </Link>
            </div>
        </div>
    )
}
