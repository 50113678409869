import React, { FunctionComponent } from 'react'
import wordmark from '../../../assets/wordmark.png'
import { SoundControlButton } from './SoundControlButton'
import { HiQuestionMarkCircle } from 'react-icons/hi'
import { RulesModalControlButton } from './RulesModalControlButton'

export interface SmallHeaderProps {
    onRulesClick: () => void
}

export const GameHeader: FunctionComponent<SmallHeaderProps> = ({ onRulesClick }) => {
    return (
        <div style={{ display: 'grid', position: 'relative', placeItems: 'center' }}>
            <div style={{ position: 'absolute', left: 0, marginLeft: '0.7em', marginTop: '0.9em' }}>
                <RulesModalControlButton onClick={onRulesClick} />
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '0.7em',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src={wordmark} style={{ width: '40%' }} alt="wordmark.png" title="Wordmark of game" />
            </div>
            <div style={{ position: 'absolute', right: 0, marginRight: '0.7em', marginTop: '0.9em' }}>
                <SoundControlButton />
            </div>
        </div>
    )
}
