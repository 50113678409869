export const A_WORDS = [
    'AVION',
    'AKTER',
    'ASTMA',
    'AROMA',
    'ALBUM',
    'AGAVA',
    'ALARM',
    'AGENT',
    'ARENA',
    'AFEKT',
    'ALEJA',
    'ALEJA',
    'AFERA',
    'AGENS',
    'AKORD',
    'AMBAR',
    'AUTOR',
    'ANĐEO',
    'ATAŠE',
    'ATEST',
    'ANDOL',
    'ANODA',
    'AUDIO',
    'ANODA',
    'ARSEN',
    'ARŠIN',
    'AMBIS',
    'ARHIV',
    'AGENT',
    'ATLAS',
    'APRIL',
    'ATELjE',
]
