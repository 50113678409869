export const H_WORDS = [
    'HOTEL',
    'HRANA',
    'HITNA',
    'HUMOR',
    'HOKEJ',
    'HORDA',
    'HELjDA',
    'HVALA',
    'HEROJ',
    'HITRO',
    'HITAC',
    'HRANA',
    'HOTEL',
    'HEROJ',
    'HITAC',
    'HARFA',
    'HIMNA',
    'HAUBA',
    'HOBIT',
    'HOROR',
    'HRAST',
    'HRČAK',
    'HRĐAV',
    'HRIDA',
]
