import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../elements/Button'
import { Header } from '../Header/Header'
import { RulesModal } from '../Rules/RulesModal'

export interface HomeProps {}

export const Home: FunctionComponent<HomeProps> = () => {
    const [openRules, setOpenRules] = useState(false)

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                gap: '4em',
            }}
        >
            <div
                style={{
                    marginTop: '1em',
                    marginBottom: '7em',
                }}
            >
                <Header />
            </div>
            <Link to={'/igra'}>
                <Button text={'Igra'} />
            </Link>
            <div style={{ paddingTop: '3em' }}>
                <Button text={'Pravila'} onClick={() => setOpenRules(true)} size={'small'} />
            </div>
            {openRules && <RulesModal onClose={() => setOpenRules(false)} />}
        </div>
    )
}
