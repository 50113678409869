export const OLD_WORDS = [
    'MUŠKO',
    'MAČKA',
    'STENA',
    'VREME',
    'PESAK',
    'LETAK',
    'SNAGA',
    'TRAVA',
    'PAPIR',
    'KVAKA',
    'LISTA',
    'KAPUT',
    'GOSTI',
    'SVEĆA',
    'SUNCE',
    'ŠKOLA',
    'RUKAV',
    'TANAK',
    'SMENA',
    'SVETA',
    'GLAVA',
    'MOLBA',
    'IGRAČ',
    'CRVEN',
    'TEŠKO',
    'SLIKA',
    'PLAVA',
    'SVEŽE',
    'DASKA',
    'PESMA',
    'SREDI',
    'DEČAK',
    'SALAŠ',
    'PAMET',
    'PČELA',
    'VELIK',
    'ZUBAR',
]
