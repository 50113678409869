export const Zh_WORDS = [
    'ŽURKA',
    'ŽARKO',
    'ŽALBA',
    'ŽIVOT',
    'ŽILET',
    'ŽRTVA',
    'ŽILAV',
    'ŽABAC',
    'ŽENKA',
    'ŽIVAC',
    'ŽUBOR',
    'ŽVAKA',
    'ŽEDAN',
]
