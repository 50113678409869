export const F_WORDS = [
    'FARMA',
    'FRULA',
    'FOKUS',
    'FIKUS',
    'FRAZA',
    'FLORA',
    'FAUNA',
    'FLOTA',
    'FIRMA',
    'FREZA',
    'FIKUS',
    'FRAZA',
    'FOKUS',
    'FOSIL',
    'FARBA',
    'FARSA',
    'FELNA',
    'FENjER',
    'FETUS',
    'FILET',
    'FIOKA',
    'FIŠEK',
    'FITILj',
    'FJORD',
    'FLAŠA',
    'FLEKA',
    'FLUOR',
    'FORMA',
    'FORUM',
    'FRAPE',
    'FRTALj',
    'FUNTA',
]
