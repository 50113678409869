export const I_WORDS = [
    'IDEJA',
    'IKONA',
    'IZLET',
    'IGRAČ',
    'ISTOK',
    'IZBOR',
    'IZVOR',
    'ISPIT',
    'IVICA',
    'ISKRA',
    'IZDAH',
    'IDIOT',
    'IZLAZ',
    'IZLOG',
    'IDILA',
    'ISKAZ',
    'IZDAN',
    'IZMET',
    'IZVOZ',
    'IZRAZ',
]
