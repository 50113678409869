import React, { FunctionComponent } from 'react'

export interface RulesContentProps {}

export const RulesContent: FunctionComponent<RulesContentProps> = () => {
    return (
        <div>
            <p>Cilj ove igre je da pogodite zadanu reč.</p>
            <p>Zadana reč ima 5 slova.</p>
            <p>Imate 6 pokušaja da pogodite reč.</p>
            <p>
                Nakon što unesete reč, svako slovo biće označeno bojom koja Vam signalizuje koliko ste blizu konačnog rešenja:
                <ul>
                    <li>
                        <strong>Zelena boja:</strong> Slovo se nalazi u reči i na pravom je mestu.
                    </li>
                    <li>
                        <strong>Žuta boja:</strong> Slovo se nalazi u reči, ali nije na pravom mestu.
                    </li>
                    <li>
                        <strong>Tamno siva boja:</strong> Slovo se ne nalazi u reči.
                    </li>
                </ul>
            </p>
            <br />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <p style={{ color: '#007d80', fontFamily: 'Comic Sans MS', fontWeight: 'bolder', fontSize: '1.7em' }}>
                    <strong>SREĆNO</strong>
                </p>
            </div>
        </div>
    )
}
