export const P_WORDS = [
    'PESMA',
    'PEVAČ',
    'PUPAK',
    'PAKAO',
    'PAKET',
    'PESAK',
    'PLAVO',
    'POZOR',
    'POPIS',
    'PEKAR',
    'PASTA',
    'POVOD',
    'PLUTA',
    'PLAST',
    'POREZ',
    'PLAŠT',
    'PRSTI',
    'PAPIR',
    'PEČAT',
    'PROST',
    'PATKA',
    'PROTA',
    'PEČEN',
    'PETAO',
    'POZOR',
    'PRKOS',
    'PRVAK',
    'PTICA',
    'PELAT',
    'PERUT',
    'PACOV',
    'PISTA',
    'PUMPA',
    'PAŽNjA',
    'PILOT',
    'POŽAR',
    'PLUĆA',
    'PRUGA',
    'PAUZA',
    'PUŠKA',
    'PANDA',
    'PRANJE',
    'PASULj',
    'POŠTA',
    'POVEZ',
    'PERLA',
    'PLOČA',
    'PRATI',
    'PONOS',
    'PEHAR',
    'PUTAR',
    'PISAC',
    'POJAS',
    'PRŠUT',
    'POGON',
    'PLEME',
    'PLAŽA',
    'PLIMA',
    'POMOĆ',
    'PRAVO',
    'PRAVI',
    'POJAM',
    'POHOD',
    'PORAZ',
]
