export const N_WORDS = [
    'NAFTA',
    'NAČIN',
    'NEŠTO',
    'NIKAD',
    'NOĆNI',
    'NIŠTA',
    'NOVAC',
    'NAČIN',
    'NAMAZ',
    'NEMIR',
    'NAROD',
    'NORMA',
    'NAFTA',
    'NOTAR',
    'NALAZ',
    'NAPAD',
    'NAPOR',
    'NAUKA',
    'NAZIV',
]
