import { Button } from '../elements/Button'
import { Link } from 'react-router-dom'
import React from 'react'
import { Header } from '../Header/Header'

export const ReviewThankYou = () => {
    return (
        <div>
            <div>
                <Header />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '3em',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                    gap: '6em',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1.5em',
                    }}
                >
                    <p style={{ fontSize: '1.7em', color: '#004b4d', fontWeight: 'bolder', textAlign: 'center' }}>Hvala!</p>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '0.5em',
                        }}
                    >
                        <p style={{ fontSize: '1.2em', color: '#004b4d', fontWeight: 'bolder', textAlign: 'center' }}>
                            Veoma smo Vam zahvalni, što ovom recenzijom doprinosite poboljšanju igrice.
                        </p>
                        <p style={{ fontSize: '1.2em', color: '#004b4d', fontWeight: 'bolder', textAlign: 'center' }}>
                            Želimo Vam prijatan dan i puno sreće u daljem igranju!
                        </p>
                    </div>
                </div>

                <Link to={'/igra'}>
                    <Button text={'Nazad na igru'} size={'small'} />
                </Link>
            </div>
        </div>
    )
}
