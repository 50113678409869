import { A_WORDS } from './letter_lists/A'
import { B_WORDS } from './letter_lists/B'
import { C_WORDS } from './letter_lists/C'
import { Ch_WORDS } from './letter_lists/Ch'
import { Chh_WORDS } from './letter_lists/Chh'
import { OLD_WORDS } from './letter_lists/Zz_OldWords'
import { E_WORDS } from './letter_lists/E'
import { F_WORDS } from './letter_lists/F'
import { D_WORDS } from './letter_lists/D'
import { Dj_WORDS } from './letter_lists/Dj'
import { Dz_WORDS } from './letter_lists/Dz'
import { G_WORDS } from './letter_lists/G'
import { H_WORDS } from './letter_lists/H'
import { I_WORDS } from './letter_lists/I'
import { J_WORDS } from './letter_lists/J'
import { K_WORDS } from './letter_lists/K'
import { L_WORDS } from './letter_lists/L'
import { Lj_WORDS } from './letter_lists/Lj'
import { M_WORDS } from './letter_lists/M'
import { N_WORDS } from './letter_lists/N'
import { Nj_WORDS } from './letter_lists/Nj'
import { O_WORDS } from './letter_lists/O'
import { P_WORDS } from './letter_lists/P'
import { Z_WORDS } from './letter_lists/Z'
import { V_WORDS } from './letter_lists/V'
import { U_WORDS } from './letter_lists/U'
import { T_WORDS } from './letter_lists/T'
import { S_WORDS } from './letter_lists/S'
import { R_WORDS } from './letter_lists/R'
import { Zh_WORDS } from './letter_lists/Zh'
import { isLengthValid } from '../../service/words/wordsService'

export const getRandomWord = () => {
    return WORDS[Math.floor(Math.random() * WORDS.length)]
}

export const getWordCount = () => {
    return WORDS.length
}

const WORDS_PLAIN = [
    ...A_WORDS,
    ...B_WORDS,
    ...C_WORDS,
    ...Ch_WORDS,
    ...Chh_WORDS,
    ...D_WORDS,
    ...Dj_WORDS,
    ...Dz_WORDS,
    ...E_WORDS,
    ...F_WORDS,
    ...G_WORDS,
    ...H_WORDS,
    ...I_WORDS,
    ...J_WORDS,
    ...K_WORDS,
    ...L_WORDS,
    ...Lj_WORDS,
    ...M_WORDS,
    ...N_WORDS,
    ...Nj_WORDS,
    ...O_WORDS,
    ...P_WORDS,
    ...R_WORDS,
    ...S_WORDS,
    ...T_WORDS,
    ...U_WORDS,
    ...V_WORDS,
    ...Z_WORDS,
    ...Zh_WORDS,
    ...OLD_WORDS,
]

const WORDS = [...new Set(WORDS_PLAIN.filter((word) => isLengthValid(word)))]
