import { FC, useEffect } from 'react'
import useSound from 'use-sound'
import win from '../../../sounds/win.wav'
import { track } from '@vercel/analytics'

export interface WinBannerProps {
    sound: boolean
    answer: string
}

export const WinBanner: FC<WinBannerProps> = ({ sound, answer }) => {
    const [play] = useSound(win)

    useEffect(() => {
        track('GameDone', { result: 'win' })
        if (sound) {
            play()
        }
    }, [sound, play])

    return (
        <div>
            <p style={{ fontSize: '1.1em' }}>
                <strong>Čestitamo! Pobedili ste.</strong>
            </p>
            <p style={{ fontSize: '1.1em' }}>
                Pobednička reč je: <strong>{answer}</strong>
            </p>
        </div>
    )
}
