import { FC } from 'react'
import { Link } from 'react-router-dom'
import { SocialButton } from './SocialButton'

export interface SocialLinksContentProps {
    onButtonClick: () => void
}

export const SocialLinksContent: FC<SocialLinksContentProps> = ({ onButtonClick }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                fontSize: '1.2em',
                fontFamily: 'Calibri, sans-serif',
                gap: '1em',
            }}
        >
            <p>Zapratite nas na socijalnim mrežama</p>
            <Link to={'https://www.facebook.com/share/CoCWS3uCHoNWYtvH/'}>
                <SocialButton type="FB" onClick={onButtonClick} />
            </Link>
            <Link to={'https://www.instagram.com/pogadjalicaa?utm_source=qr&igsh=MWlxZTlyc3JkbXVkbg%3D%3D'}>
                <SocialButton type="IG" onClick={onButtonClick} />
            </Link>
        </div>
    )
}
