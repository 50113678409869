import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Home } from './Home/Home'
import { GameWrapper } from './Game/GameWrapper'
import { Review } from './Review/Review'
import { ReviewThankYou } from './Review/ReviewThankYou'

function App() {
    return (
        <div
            style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                padding: '4px',
                width: `100%`,
                backgroundColor: 'hsl(208, 100%, 98%)',
            }}
        >
            <BrowserRouter>
                <h1 style={{ display: 'none' }}>Pogađalica</h1>
                <h2 style={{ display: 'none' }}>Igra pogađanja reči</h2>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/igra" element={<GameWrapper />} />
                    <Route path="/ocena" element={<Review />} />
                    <Route path="/zahvalnica" element={<ReviewThankYou />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
