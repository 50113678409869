export const J_WORDS = [
    'JAHTA',
    'JAVNO',
    'JECAJ',
    'JEDAN',
    'JEDRO',
    'JESEN',
    'JEZIK',
    'JUNAK',
    'JURIŠ',
    'JUTRO',
    'JAKNA',
    'JEZIK',
    'JETRA',
    'JURIŠ',
    'JARAC',
    'JAGNjE',
    'JELEN',
    'JEDAN',
]
