export const S_WORDS = [
    'SEVER',
    'STVAR',
    'SILOS',
    'SUNCE',
    'SAJAM',
    'SLOVO',
    'SITNO',
    'SIRUP',
    'STRES',
    'SERUM',
    'SKROB',
    'SVINjA',
    'SEOBA',
    'SONET',
    'SLIKA',
    'SAJLA',
    'SAPUN',
    'SLUGA',
    'SAUNA',
    'SLAVA',
    'SARMA',
    'SOMUN',
    'SKLAD',
    'SALON',
    'SLINA',
    'SLATI',
    'STRIC',
    'SRČAN',
    'SMETI',
    'SKICA',
    'SVILA',
    'SVEST',
    'STANjE',
    'STAZA',
    'SEČVA',
    'SAVEZ',
    'SVRHA',
    'SPORT',
    'SLAMA',
    'SNAJA',
    'STENA',
    'SUKNjA',
    'SLANO',
    'SUKOB',
    'SMESA',
    'SREDA',
    'SEDAM',
    'SCENA',
]
