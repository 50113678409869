export const D_WORDS = [
    'DUVAN',
    'DIVAN',
    'DUPLO',
    'DOKAZ',
    'DRUGA',
    'DRUGI',
    'DRAGA',
    'DUKAT',
    'DIZNA',
    'DRAMA',
    'DASKA',
    'DUBOK',
    'DIVLjE',
    'DEČAK',
    'DATUM',
    'DESNO',
    'DUPLO',
    'DOMET',
    'DRVEN',
    'DEBEO',
    'DUGME',
    'DODIR',
    'DEVER',
    'DRŽAČ',
    'DONOR',
    'DINAR',
    'DIZEL',
    'DUGME',
    'DEVET',
    'DABAR',
    'DANAS',
    'DATIV',
    'DAVEŽ',
    'DEBLO',
    'DEČKO',
    'DEKAN',
    'DELTA',
    'DEOBA',
    'DESNI',
    'DETALj',
    'DIODA',
    'DIPLE',
    'DLAKA',
    'DOČEK',
    'DOLAR',
    'DOMAR',
    'DOMEN',
    'DOPIS',
    'DOVOD',
    'DRATI',
    'DRŠKA',
    'DRVCE',
    'DRZAK',
]
