import { FC } from 'react'

export interface KeyboardKeyProps {
    letter: string
    status: string
    active: boolean
    onClick: (letter: string) => void
}

export const KeyboardKey: FC<KeyboardKeyProps> = ({ letter, status, active, onClick }) => {
    const width = ['Lj'].includes(letter) ? '8%' : ['Nj'].includes(letter) ? '8%' : ['Dž'].includes(letter) ? '10%' : '7%'
    const color = status === 'inactive' ? 'black' : 'white'
    const statusColors: Record<any, string> = {
        inactive: 'lightgray',
        incorrect: 'var(--color-incorrect-letter)',
        correct: 'var(--color-correct-letter)',
        misplaced: 'var(--color-misplaced-letter)',
    }

    const windowWidth = window.innerWidth
    const fontSize = windowWidth <= 600 ? '6vw' : '1.7rem'

    return (
        <button
            onClick={() => onClick(letter)}
            disabled={!active}
            style={{
                width: width,
                margin: '0.5%',
                padding: '0.5em',
                height: '100%',
                fontSize: fontSize,
                border: '1px solid var(--color-gray-700)',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bolder',
                color: color,
                backgroundColor: statusColors[status],
                borderRadius: '0.4rem',
            }}
        >
            {letter}
        </button>
    )
}
