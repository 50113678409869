import { FC } from 'react'
import { range } from '../../../service/utils'
import { GUESS_LENGTH } from '../../../data/constants'
import { LetterBox } from './LetterBox'
import { getGuessResult } from '../../../service/game/gameService'

export interface GuessProps {
    answer: string
    guess: string[]
    isActive: boolean
}

export const Guess: FC<GuessProps> = ({ guess, answer, isActive }) => {
    const guessValue = guess ? guess : []
    const result: any[] = !isActive && guess ? getGuessResult(guessValue, answer) : []

    return (
        <div
            style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <p
                style={{
                    display: 'flex',
                    gap: '4px',
                    marginBottom: '4px',
                }}
            >
                {range(GUESS_LENGTH).map((index) => (
                    <LetterBox key={index} letter={guessValue[index]} status={result[index] ? result[index].status : 'inactive'} />
                ))}
            </p>
        </div>
    )
}
