export const K_WORDS = [
    'KAJAK',
    'KAFIĆ',
    'KAPUT',
    'KAPAK',
    'KAZNA',
    'KAVEZ',
    'KOBNO',
    'KABAL',
    'KADET',
    'KAKAO',
    'KALUP',
    'KANAL',
    'KANAP',
    'KARTA',
    'KARMA',
    'KAZAN',
    'KOSTI',
    'KONAC',
    'KONCI',
    'KOALA',
    'KOLAČ',
    'KADAR',
    'KOCKA',
    'KORPA',
    'KLIMA',
    'KLUPA',
    'KANTA',
    'KNjIGA',
    'KIFLA',
    'KUPUS',
    'KEČAP',
    'KAMEN',
    'KLAPA',
    'KONTA',
    'KOPLjE',
    'KRUNA',
    'KLASA',
    'KASNO',
    'KONjAK',
    'KRILO',
    'KOTAO',
    'KORAK',
    'KUPAC',
    'KLOVN',
    'KOLAC',
    'KOFER',
    'KOSKA',
    'KIČMA',
    'KUBIK',
    'KOMAD',
    'KUVAR',
    'KOMBI',
    'KOROV',
    'KULEN',
    'KANjON',
    'KRIZA',
    'KRIVO',
]
