import { FC } from 'react'

export interface LetterBoxProps {
    letter: string
    status: string
}

export const LetterBox: FC<LetterBoxProps> = ({ letter, status }) => {
    const backgroundColorMap: Record<any, string> = {
        incorrect: 'var(--color-incorrect-letter)',
        correct: 'var(--color-correct-letter)',
        misplaced: 'var(--color-misplaced-letter)',
        inactive: 'transparent',
    }

    return (
        <span
            style={{
                position: 'relative',
                width: '20%',
                display: 'grid',
                placeContent: 'center',
                aspectRatio: '1/1',
                border: '2px solid var(--color-gray-700)',
                borderRadius: '0.25em',
                fontSize: '2rem',
                backgroundColor: status ? backgroundColorMap[status] : 'transparent',
                color: status === 'inactive' ? 'black' : 'white',
                fontWeight: 'bold',
            }}
        >
            {letter ? letter : ''}
        </span>
    )
}
