import { FC } from 'react'
import { FIRST_ROW_LETTERS, SECOND_ROW_LETTERS, THIRD_ROW_LETTERS } from '../../../data/constants'
import { KeyboardLine } from './KeyboardLine'

export interface KeyboardProps {
    statusMap: Map<string, string>
    active: boolean
    onKeyClick: (letter: string) => void
    onEnter: () => void
    onDelete: () => void
}

export const Keyboard: FC<KeyboardProps> = ({ statusMap, active, onKeyClick, onEnter, onDelete }) => {
    const firstRowLetters = FIRST_ROW_LETTERS
    const secondRowLetters = SECOND_ROW_LETTERS
    const thirdRowLetters = THIRD_ROW_LETTERS

    const firstRow = new Map(Array.from(statusMap).filter((letter) => firstRowLetters.includes(letter[0])))
    const secondRow = new Map(Array.from(statusMap).filter((letter) => secondRowLetters.includes(letter[0])))
    const thirdRow = new Map(Array.from(statusMap).filter((letter) => thirdRowLetters.includes(letter[0])))

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <KeyboardLine
                letters={firstRow}
                lineType={'upper'}
                active={active}
                onKeyClick={onKeyClick}
                onEnter={onEnter}
                onDelete={onDelete}
            />
            <KeyboardLine
                letters={secondRow}
                lineType={'middle'}
                active={active}
                onKeyClick={onKeyClick}
                onEnter={onEnter}
                onDelete={onDelete}
            />
            <KeyboardLine
                letters={thirdRow}
                lineType={'lower'}
                active={active}
                onKeyClick={onKeyClick}
                onEnter={onEnter}
                onDelete={onDelete}
            />
        </div>
    )
}
