export const C_WORDS = [
    'CREVO',
    'CRTEŽ',
    'CIVIL',
    'CISTA',
    'CIFRA',
    'CINIK',
    'CIMET',
    'CRKVA',
    'CRVEN',
    'CUCLA',
    'CVEĆE',
    'CREVO',
    'CIGLA',
    'CELER',
    'CITAT',
    'CESTA',
]
