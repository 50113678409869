export const M_WORDS = [
    'MELEM',
    'MOTOR',
    'MAJKA',
    'MOLBA',
    'METAL',
    'MASKA',
    'MAŠTA',
    'MOZAK',
    'MOŽDA',
    'MONAH',
    'MAČKA',
    'MOMAK',
    'MASNO',
    'MUZEJ',
    'METAN',
    'MESTO',
    'MAGLA',
    'MAMAC',
    'MAŠNA',
    'MESTO',
    'MOTIV',
    'MESAR',
    'MALER',
    'MIŠIĆ',
    'MEDIJ',
    'MAMAC',
    'MREŽA',
    'MANGO',
    'MANIR',
    'MARKA',
    'MEDEN',
    'MEHUR',
    'MENZA',
    'METAK',
    'METAR',
    'METIL',
    'METLA',
    'METOD',
    'METRO',
    'MLADA',
    'MENjAČ',
    'MESEC',
    'MRTAV',
    'MODEL',
]
