import { FC } from 'react'
import { FaDeleteLeft } from 'react-icons/fa6'

export interface DeleteKeyProps {
    active: boolean
    onClick: () => void
}

export const DeleteKey: FC<DeleteKeyProps> = ({ active, onClick }) => {
    const windowWidth = window.innerWidth
    const fontSize = windowWidth <= 600 ? '6.5vw' : '2rem'

    return (
        <button
            onClick={onClick}
            disabled={!active}
            style={{
                width: 'fit-content',
                margin: '0.5%',
                padding: '0.3em 1em',
                fontSize: fontSize,
                border: '1px solid var(--color-gray-700)',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bolder',
                color: 'white',
                backgroundColor: 'darkred',
                borderRadius: '0.4rem',
            }}
        >
            <FaDeleteLeft />
        </button>
    )
}
