import React, { FC } from 'react'
import { HiQuestionMarkCircle } from 'react-icons/hi'

export interface RulesModalControlButtonProps {
    onClick: () => void
}

export const RulesModalControlButton: FC<RulesModalControlButtonProps> = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            style={{
                fontSize: '2em',
                color: '#a6a6a6',
            }}
        >
            <HiQuestionMarkCircle />
        </div>
    )
}
