export const Z_WORDS = [
    'ZAPAD',
    'ZEBRA',
    'ZAVOD',
    'ZIDAR',
    'ZELEN',
    'ZANAT',
    'ZAPIS',
    'ZALIV',
    'ZAMAK',
    'ZUBAR',
    'ZEBRA',
    'ZMIJA',
    'ZAČIN',
    'ZEMLjA',
    'ZLATO',
    'ZVONO',
    'ZALIV',
    'ZAMOR',
    'ZAPIS',
    'ZALOG',
    'ZAREZ',
    'ZASAD',
    'ZAVOD',
    'ZELEN',
    'ZLOBA',
    'ZAJAM',
    'ZAVET',
    'ZAVOJ',
    'ZAOVA',
    'ZAKON',
    'ZAMAH',
    'ZGLOB',
    'ZADNjI',
    'ZADNjA',
]
