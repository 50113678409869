export const T_WORDS = [
    'TENIS',
    'TAMNO',
    'TIGAR',
    'TIKVA',
    'TUNEL',
    'TRAVA',
    'TAJNA',
    'TOPAO',
    'TEMPO',
    'TREĆI',
    'TESNO',
    'TORBA',
    'TORTA',
    'TEPIH',
    'TANjIR',
    'TIGANj',
    'TIKVA',
    'TAJNA',
    'TOPLO',
    'TAKSI',
    'TOVAR',
    'TUŽNO',
    'TABOR',
    'TANKO',
    'TANAK',
    'TAROT',
    'TEGLA',
    'TAVAN',
    'TETKA',
    'TESTO',
    'TETAK',
    'TEŽAK',
    'TIPKA',
    'TUMAČ',
    'TONUS',
    'TORZO',
    'TEREN',
    'TRKAČ',
    'TRASA',
    'TAŠTA',
    'TKIVO',
    'TAŠNA',
    'TEROR',
    'TOČAK',
    'TALAS',
    'TERET',
    'TRAKA',
    'TREND',
]
