export const NUM_OF_GUESSES_ALLOWED = 6
export const GUESS_LENGTH = 5

export const FIRST_ROW_LETTERS = ['E', 'R', 'T', 'U', 'I', 'O', 'P', 'Š', 'Đ', 'Ž']
export const SECOND_ROW_LETTERS = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Č', 'Ć', 'Nj']
export const THIRD_ROW_LETTERS = ['Z', 'C', 'V', 'B', 'N', 'M', 'Dž', 'Lj']

export const INITIAL_LETTERS_STATUS_MAP: [string, string][] = [...FIRST_ROW_LETTERS, ...SECOND_ROW_LETTERS, ...THIRD_ROW_LETTERS].map(
    (letter) => [letter, 'inactive'],
)
