import { FunctionComponent } from 'react'

export interface ButtonProps {
    text: string
    type?: 'submit'
    disable?: boolean
    onClick?: () => void
    size?: 'large' | 'small'
}

export const Button: FunctionComponent<ButtonProps> = ({ text, type, disable, onClick, size = 'large' }) => {
    const fontSize = size === 'large' ? '1.6em' : '1.3em'
    const borderThickness = size === 'large' ? '2.5px' : '2px'

    return (
        <button
            style={{
                border: `${borderThickness} solid #00191a`,
                borderRadius: '36px',
                padding: '8px 24px',
                fontSize: `${fontSize}`,
                cursor: 'pointer',
                backgroundColor: '#007d80',
                color: 'white',
                fontWeight: 100,
                fontFamily: 'Georgia, sans-serif',
                width: '6em',
            }}
            onClick={onClick}
            type={type}
            disabled={disable!!}
        >
            {text}
        </button>
    )
}
