import { FC } from 'react'

export interface EnterKeyProps {
    active: boolean
    onClick: () => void
}

export const EnterKey: FC<EnterKeyProps> = ({ active, onClick }) => {
    const windowWidth = window.innerWidth
    const fontSize = windowWidth <= 600 ? '4.3vw' : '1.4rem'

    return (
        <button
            onClick={onClick}
            disabled={!active}
            style={{
                width: 'fit-content',
                margin: '0.5%',
                padding: '0.5em 0.9em',
                fontSize: fontSize,
                border: '1px solid var(--color-gray-700)',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bolder',
                color: 'white',
                backgroundColor: 'darkgreen',
                borderRadius: '0.4rem',
            }}
        >
            {'ENTER'}
        </button>
    )
}
