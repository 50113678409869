export const V_WORDS = [
    'VREME',
    'VITKA',
    'VOĆKA',
    'VREĆA',
    'VOKAL',
    'VESLO',
    'VESTI',
    'VIŠNjA',
    'VRATA',
    'VAZNA',
    'VARKA',
    'VRSTA',
    'VATRA',
    'VETAR',
    'VITAK',
    'VOĆAR',
    'VOJNO',
    'VOLAN',
    'VOZNI',
    'VIZIR',
    'VOKAL',
    'VOZAČ',
    'VINjAK',
    'VRANA',
    'VRUĆE',
    'VOSAK',
    'VENAC',
    'VLAST',
    'VLADA',
]
