import { FunctionComponent } from 'react'
import { track } from '@vercel/analytics'

export interface SocialButtonProps {
    type: 'FB' | 'IG'
    onClick: () => void
}

export const SocialButton: FunctionComponent<SocialButtonProps> = ({ type, onClick }) => {
    return (
        <button
            style={{
                border: type === 'FB' ? '3px solid #073878' : '3px solid #782152',
                borderRadius: '36px',
                padding: '8px 24px',
                fontSize: '1.1em',
                cursor: 'pointer',
                backgroundColor: type === 'FB' ? '#1877F2' : '#E1306C',
                color: 'white',
                fontWeight: 100,
                fontFamily: 'Georgia, sans-serif',
                minWidth: '9em',
            }}
            onClick={() => {
                onClick()
                if (type === 'FB') {
                    track('SocialButtonClick-FB')
                } else {
                    track('SocialButtonClick-IG')
                }
            }}
        >
            {type === 'FB' ? 'Facebook' : 'Instagram'}
        </button>
    )
}
