export const O_WORDS = [
    'OBLIK',
    'OKLOP',
    'OBROK',
    'OPŠTE',
    'OBALA',
    'OBLAK',
    'OSNOV',
    'OTIĆI',
    'OTVOR',
    'OTROV',
    'OKVIR',
    'OMLET',
    'OBITI',
    'OBRVA',
    'OBZIR',
    'ODELO',
    'ODNOS',
    'ODRON',
    'ODZIV',
    'OGLED',
    'OKTAN',
    'OSNOV',
    'OBLAK',
    'OBLIK',
    'OBLOG',
    'OBRAZ',
    'ODBOR',
    'ODNOS',
    'OSUDA',
    'ODRAZ',
    'OBRED',
    'ODRED',
    'ODRON',
    'OGLED',
    'OGREV',
    'OTPOR',
    'ODEĆA',
    'OSOBA',
    'OTPAD',
    'OKLOP',
    'OKOLO',
    'OKOVI',
    'OLUJA',
    'OPASA',
    'OPERA',
    'OPŠTA',
    'ORGAN',
    'OKEAN',
    'OSEKA',
    'OKRUG',
    'ORUĐE',
]
