import { FunctionComponent } from 'react'

export interface BannerButtonProps {
    text: string
    onClick?: () => void
    size?: 'm' | 'l'
}

export const BannerButton: FunctionComponent<BannerButtonProps> = ({ text, onClick, size }) => {
    return (
        <button
            style={{
                border: '2px solid #666666',
                borderRadius: '36px',
                padding: '8px 24px',
                fontSize: size === 'l' ? '1.25em' : '1.1em',
                cursor: 'pointer',
                backgroundColor: '#e6e6e6',
                color: 'black',
                fontWeight: 100,
                fontFamily: 'Georgia, sans-serif',
                minWidth: size === 'l' ? '12em' : '11em',
            }}
            onClick={onClick}
        >
            {text}
        </button>
    )
}
