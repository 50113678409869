import React, { FormEvent, useState } from 'react'
import { useForm } from '@formspree/react'
import { RatingStars } from './RatingStars'
import { Header } from '../Header/Header'
import { useNavigate } from 'react-router-dom'
import { Button } from '../elements/Button'
import { track } from '@vercel/analytics'

export const Review = () => {
    const navigate = useNavigate()

    const [state, handleSubmit] = useForm('xzzpwpyj')
    const [rating, setRating] = useState(0)

    if (state.succeeded) {
        navigate('/zahvalnica')
    }

    const mySubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (rating === 0) {
            window.alert('Morate da unsete broj zvezdica')
        } else {
            handleSubmit(e)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Header />
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '3em',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                }}
            >
                <form onSubmit={mySubmit} style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '1em' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3em', width: '100%' }}>
                            <label htmlFor="stars" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                Ocena
                            </label>
                            <RatingStars rating={rating} setRating={setRating} />
                            <input type="hidden" name="value" value={rating} required={true} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5em', width: '100%' }}>
                            <label htmlFor="message" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                Poruka (opcionalno)
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                style={{
                                    width: '100%',
                                    height: '120px',
                                    fontSize: '16px',
                                    padding: '0.5em',
                                    borderRadius: '8px',
                                    resize: 'none',
                                }}
                            />
                        </div>
                        <div>
                            <Button
                                size={'small'}
                                onClick={() => track('SendReview')}
                                text={'Pošalji'}
                                type="submit"
                                disable={state.submitting}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
