import React, { FunctionComponent, useEffect, useState } from 'react'
import { IoMdVolumeHigh, IoMdVolumeOff } from 'react-icons/io'

export interface SoundControlButtonProps {}

export const SoundControlButton: FunctionComponent<SoundControlButtonProps> = () => {
    const [key, setKey] = useState<number>(Math.random())

    useEffect(() => {
        if (localStorage.getItem('sound') === null) {
            localStorage.setItem('sound', 'true')
            setKey(Math.random())
        }
    }, [key])

    return (
        <div
            key={key}
            onClick={() => {
                if (localStorage.getItem('sound') === 'true') {
                    localStorage.setItem('sound', 'false')
                } else {
                    localStorage.setItem('sound', 'true')
                }
                setKey(Math.random())
            }}
            style={{
                fontSize: '2em',
                color: '#a6a6a6',
            }}
        >
            {localStorage.getItem('sound') === 'true' ? <IoMdVolumeHigh /> : <IoMdVolumeOff />}
        </div>
    )
}
