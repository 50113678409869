export const isLengthValid = (word: string) => {
    const allowed_len = 5
    const additional_allowed_len = occurrences(word, 'Dž') + occurrences(word, 'Lj') + occurrences(word, 'Nj')

    return word.length === allowed_len + additional_allowed_len
}

const occurrences = (string: string, subString: string) => {
    string += ''
    subString += ''
    if (subString.length <= 0) return string.length + 1

    var n = 0,
        pos = 0,
        step = subString.length

    while (true) {
        pos = string.indexOf(subString, pos)
        if (pos >= 0) {
            ++n
            pos += step
        } else break
    }
    return n
}
